.card-level {
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    position: relative;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    & .card-level-user {
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: white;
    }

    &.golden {
        border-color: #FFD700;

    }

    &.platinum {
        border-color: #E5E4E2;
    }
}