.department-container {
    margin-top: 5rem;
    color: #7C7C7C;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .department-gallery {
        overflow: hidden;
        padding: 20px 0;

        .carousel-strip {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 10px;
            padding: 10px;
            transition: transform 0.8s ease;

            img {

                object-fit: cover;
                border-radius: 32px;
                cursor: pointer;
                transition: all .9s ease;

                &.side {
                    height: 564px;
                    width: 423px;
                    opacity: 0.7;

                    &:hover {
                        opacity: 0.9;
                    }
                }

                &.first,
                &.last {
                    width: 119px;
                }


                &.center {
                    flex-grow: 1;
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                }
            }
        }
    }

    section.department-information {
        display: flex;
        margin-top: 40px;
        padding: 0px 144px 40px 144px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;

        .department-title {
            color: #025928;
            text-align: center;
            font-family: Junge;
            font-size: clamp(2rem, 3vh, 2rem);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 13.92px;
            max-width: 798px;
        }

        .department-content-text {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            gap: 119px;
            align-self: stretch;

            &__description {
                color: #7C7C7C;
                text-align: justify;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 178.722%;
                max-width: 515px;
            }

            &__description {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    .department-content-text__comodities {
        width: 449px;

        /*         &-services {}
 */
        .department-content-text__comodities-title {
            color: #1E1E1E;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .department-content-text__comodities-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            &-item {
                max-width: 105px;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #1E1E1E;
                text-align: center;
                font-size: 11px;

            }
        }
    }

    section.department-amenities-services {
        display: flex;
        padding: 80px 124px;
        justify-content: space-around;
        align-items: flex-start;
        gap: 241px;
        align-self: stretch;
        flex-wrap: wrap;
        background-color: #F9F9F9;

        h5 {
            color: #025928;

            font-family: Poppins;
            font-size: clamp(22px, 1vh, 24px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .department-amenities-services__amenities {
            display: flex;
            width: 427px;
            flex-direction: column;
            align-items: flex-start;
            gap: 61px;

            .department-amenities-services__amenities-list {
                display: flex;
                width: 412px;
                align-items: flex-start;
                align-content: flex-start;
                gap: 16px 0px;
                flex-wrap: wrap;

                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    width: 103px;
                    height: 102px;
                    flex-shrink: 0;

                    p {
                        color: #1E1E1E;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }


        .department-amenities-services__services {
            display: flex;
            width: 460px;
            align-items: flex-end;
            align-content: flex-end;
            gap: 64px 16px;
            flex-wrap: wrap;

            &-list {
                display: flex;
                flex-wrap: wrap;
                gap: 16px;
            }

            &-list-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 103px;
                height: 102px;
                flex-shrink: 0;

                p {
                    color: #1E1E1E;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }


            }

        }

    }
}

.service-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0;
    margin: 0;

}

@media (max-width: 576px) {
    .service-grid {

        padding: 0 10px !important;
    }

    section.department-information,
    .department-amenities-services {
        padding: 40px 16px !important;

    }

    .department-amenities-services {
        gap: 40px !important;
    }

    .department-content-text__comodities {
        width: fit-content !important;
    }

    .side.first {
        width: 80% !important;
    }
}