.zone-exchange-container {
    width: 1366px;
    margin: 0 auto;
    margin-top: 8rem;
    margin-bottom: 40px;
    max-width: 100%;
    padding: 0 1rem;
}

.zone-exchange {
    display: grid;

    gap: 32px;
    font-family: Poppins;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:

        "levelpoints memberships memberships";


    @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "levelpoints levelpoints"
            "memberships memberships";
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas: "levelpoints"
            "memberships";
        gap: 24px;
    }

    @media (max-width: 576px) {


        display: block !important;
    }

    .zone-exchange__title {
        grid-area: title;
        font-weight: 700;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 24px;
    }

    .zone-exchange__memberships {
        grid-area: memberships;

        display: flex;
        flex-direction: column;
        gap: 32px;

        .zone-exchange__memberships__text {
            font-family: Poppins;
            font-style: normal;
            line-height: normal;

            p:first-child {
                font-size: 20px;
                font-weight: 600;
                margin: 0;
            }

            p:last-child {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
            }
        }

    }

    .zone-exchange__level__points {
        grid-area: levelpoints;
        display: flex;
        flex-direction: column;
        gap: 35px;

        .zone-exchange__level {
            background-color: #EAF5E8;
            padding: 24px;

            div.body__level {
                margin-top: 10px;
                display: flex;
                gap: 10px;
                flex-direction: column;

                .body__level__title {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .body__level__history {
                    padding: 8px 16px;

                    a {
                        color: #025928;


                    }
                }
            }

            .card-data {
                border-radius: 8px;
                padding: 8px 16px;

            }

        }



        .point-reward {

            .card-body {
                p.point-reward__number {
                    display: flex;
                    align-items: center;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                }
            }
        }


    }
}

.history-points {
    display: grid;
    font-family: Poppins;
    font-style: normal;
    line-height: 32px;
    letter-spacing: 0.2px;
    grid-template-columns: 1fr;
    grid-template-areas: "title"
        "container";
    gap: 32px;
    margin-top: 32px;

    @media (max-width: 576px) {
        display: block !important;

        .history-points__container {
            margin-top: 32px;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        grid-area: title;
        display: flex;
        align-items: center;
        gap: 24px;

        &__text {
            h4 {
                font-size: 24px;
                font-weight: 600;
            }

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }


    }

    &__container {
        grid-area: container;

    }

    .history-points__container__forms {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.card-point-conversion {
    padding: 24px !important;
}