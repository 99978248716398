.booking-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100%);
    gap: 32px;
    flex-wrap: wrap;

    .booking-image {
        width: 404px;
        height: 264px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .booking-detail {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        gap: 24px;
        width: 100%;

        .booking-detail-container-service {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .booking-detail-container-service__container {
                &__title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                }

                &__service {
                    display: flex;
                    gap: 8px;
                    margin-top: 8px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #4F4F4F;
                    align-items: end;
                }
            }

        }

    }

    .booking-detail__card_price {
        background-color: #E9F8E9;
        border-radius: 24px;
        padding: 24px;
        width: 277px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__chip_discount_container {
            display: flex;
            justify-content: flex-end;
            margin: .5rem;

            & .booking-detail__card_price__chip_discount {
                text-align: right;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.162px;
                color: white;
                background-color: #396DBB;
                padding: 4px 16px;
                border-radius: 81px;
            }
        }


        &__price {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;


            &__discount {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                text-decoration-line: line-through;
            }

            &__total_cost {
                font-size: 28px;
                margin: 0;
                font-weight: 600;
                line-height: 30px;
            }
        }


    }

    .booking {
        width: 100%;
        display: flex;
        background-color: #F9F9FB;

        &-backoffice {
            flex-direction: row-reverse;
            width: 100%;
            display: flex;
            background-color: #F9F9FB;

            .booking-image {
                width: 50%;
                height: 429px;

            }

            .booking-detail {
                flex-direction: column;
                padding: 0;
                width: 50%;
            }

            .booking-detail-container-service {
                padding: 16px;
            }

            .booking-detail__card_price {
                width: 100%;
                border-radius: 0;
            }
        }

        /*   gap: 16px; */


    }


}

@media (max-width: 1000px) {
    .booking-grid {
        grid-template-columns: repeat(auto-fit, 100%);
    }

    .booking {
        flex-direction: column;

        .booking-image {
            height: 264px !important;
            width: 100% !important;
        }

        .booking-detail__card_price {
            width: 100% !important;
        }

        .booking-detail {
            flex-direction: column;
            gap: 16px;
            padding: 16px;

        }

    }

    .booking-backoffice {
        flex-direction: column !important;

        & .booking-image {
            width: 100% !important;
            height: 264px !important;
        }

        & .booking-detail {
            width: 100% !important;
        }

    }
}