@import '../default/default-fonts';


.storybook-button {
  cursor: pointer;
  display: inline-block;
  line-height: 16px;
}

/*------------Primary-----------------*/
.storybook-button--primary {
  @extend .poppins-semibold;
  background: #025928;
  color: white;
  border: 1px solid #025928;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  gap: 4px;
  box-shadow: none;

  &.add-button {
    width: 180px;
  }

  &.add-button-forms {
    width: 289px;
  }

  &.form {
    width: 100px;
  }

  &.with-state-active {
    border-radius: 40px;
    width: auto;
    font-size: 16px;
    padding: 4px 12px;

  }

  &.button-detail {
    border-radius: 32px;
    @extend .poppins-regular;
  }
}

.storybook-button--primary:hover {
  background: #AED4AF;
  color: #02451F;
  border: 1px solid #02451F;
}

/*------------Secondary-----------------*/
.storybook-button--secondary {
  background: transparent;
  // padding: 8px 64px 8px 64px;
  color: #025928;
  border: 2px solid #025928 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  border-radius: 0;
  gap: 4px;
  box-shadow: none;

  &.add-login {
    padding: 8px 6px;
  }

  &.add-button {
    width: 180px;
  }

  &.add-button-forms {
    width: 289px;
  }

  &.form {
    width: 180px;
    border-radius: 4px;
  }

  &.button-detail {
    border-radius: 32px;
  }
}

.storybook-button--secondary:hover {
  background: #AED4AF;
  color: #02451F;
  border: 1px solid #02451F;
}


/*------------Tertiary-----------------*/
.storybook-button--tertiary {
  background: transparent;
  // padding: 8px 64px 8px 64px;
  color: #025928;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 0;
  gap: 4px;
  box-shadow: none;

  &.add-button {
    width: 180px;
  }
}

.storybook-button--tertiary:hover {
  background: rgb(2 89 40 / 6%);
  color: #025928;
  border-color: transparent;
}


/*------------Google-----------------*/
.storybook-button--google {
  background: #ffffff;
  border-radius: 0;
  color: #025928;
  border: 1px solid #025928;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: auto;
  width: 100%;

  &.add-google {
    padding: 6px 8px;
  }

  &.form {
    width: 100% !important;
  }
}

.storybook-button--google:hover {
  background: #639bf5;
  border-radius: 0;
  color: #ffffff;
}

/*------------Dropdown-----------------*/
.storybook-button--validatedQuantity {
  background: #C3C3C3;
  // padding: 8px 64px 8px 64px;
  color: #000000;
  border: none;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;

  &.add-button {
    width: 100%;
  }

}