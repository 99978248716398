/* You can add global styles to this file, and also import other style files */

html,
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
}

.text-decoration-none {
  text-decoration: none !important;
}

/* Layout */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout-topbar {
  flex: 0 0 auto;
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

footer {
  flex: 0 0 auto;
}

.border {
  border: 1px solid #025928;
  margin-top: 12px;
}

::ng-deep {
  .p-tabmenu .p-tabmenu-nav {
    display: flex;
    justify-content: space-between;
    height: 40px;
    border-bottom: none;
  }


  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    flex: 1;
    align-items: center;
    justify-content: center;
    background: #025928;
    border: 1px solid #ededed;
    height: 40px;
    border-radius: 24px;
  }

  .p-tabmenu .p-tabmenuitem.p-highlight .p-menuitem-text {
    color: white;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    transition: none;

    @media (max-width: 760px) {
      width: 100%;
      min-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .p-tabmenu .p-tabmenuitem {
    flex: 1;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .p-tabmenu .p-tabmenuitem:not(.p-highlight) .p-menuitem-text {
    color: #363a3e;
  }
}

::ng-deep {
  .p-selectbutton .p-button.p-highlight {
    background: red;
    background-color: red;
  }

  .p-selectbutton .p-button.p-highlight:hover {
    background: green;
  }
}

.error-message {
  color: red;
  font-size: 0.875rem;
}

.is-invalid {
  border: 2px solid red;
  border-radius: 8px;
}

@import "primeicons/primeicons.css";
@import "assets/styles/components/_level_card.scss";
@import "assets/styles/components/department_detail";
@import "assets/styles/components/exchange_zone";
@import "assets/styles/components/custom_pagination.scss";
@import "assets/styles/components/card_user.scss";
@import "assets/styles/components/_booking.scss";
@import "assets/styles/components/_room.scss";
@import "assets/styles/primeng/primeng-styles.scss";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/styles/default/default-main-styles.scss";