:root {
    --bg-state-reserved: #1A83D7;
    --bg-state-free: #8B5CF6;
    --bg-state-pending: #BC9100;
    --bg-state-payment: #139041;
    --bg-state-to-confirm: #104167;


}

.room {
    display: flex;
    flex-direction: column;

    &__details {
        display: flex;
        align-items: end;

        &__number {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
        }

    }

    &__button_call {
        width: 100%;
        border-radius: 65px;
        font-weight: 400;
        line-height: 16px;
        font-size: 14px;

        &--reserved {
            background-color: var(--bg-state-reserved);
            color: var(--bg-state-reserved);
        }

        &--free {
            background-color: var(--bg-state-free);
            color: var(--bg-state-free);
        }

        &--pending {
            background-color: var(--bg-state-pending);
            color: var(--bg-state-pending);
        }

        &--payment {
            background-color: var(--bg-state-payment);
            color: var(--bg-state-payment);
        }

        &--to-confirm {
            background-color: var(--bg-state-to-confirm);
            color: var(--bg-state-to-confirm);
        }
    }
}
.link {
    color: #1A83D7;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    &.link-primary {
        color: #025928;
    }       

}