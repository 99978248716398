// @import "./_primeng-root";
@import "./_primeng-variables";
@import "./_primeng-mixins";
@import "./_primeng-preloading";
// @import "./_primeng-main";
@import "./_primeng-topbar";
@import "./_primeng-menu";
@import "./_primeng-content";
@import "./_primeng-footer";
@import "./_primeng-responsive";
@import "./_primeng-utils";
@import "./_primeng-typography";

@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";
