.user-membership {
    margin: 32px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    align-items: center;

    &.modal-membership {
        flex-direction: column;
        align-items: start;
        margin-top: 24px;
        margin-bottom: 0;
        gap: 24px
    }

    .user-membership--card {
        display: flex;
        align-items: center;
        gap: 16px;

        .user-membership--card--user-name {
            p {
                font-size: 24px;
                font-weight: 600;
            }
        }
    }

    .user-membership--data {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        padding: 20px 16px;
        background-color: #F9F9F9;

        & div.user-membership--data--data-socio {
            border-right: 2px solid #D9D9D9;
        }

        & div:last-child {
            border-left: 2px solid #D9D9D9;
        }

        & div {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            & strong {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
}

@media (max-width: 760px) {
    .user-membership {
        justify-content: center !important;

        .user-membership--card {
            flex-direction: column-reverse;
            text-align: center;

            & img {
                width: 268px;
            }

        }

        .user-membership--data {
            flex-wrap: wrap;
            gap: 5px;
            & div:first-child {
                border-style: none !important;
                width: 100%;
            }

            & div:not(:first-child) {
                width: 48%;
            }
        }
    }
}